import React, { useContext } from "react";
import { SttTranslateHook } from '@stt-componentes/core';
import { useSignal, useSignalEffect, useSignals } from "@preact/signals-react/runtime";
import socket from "../../signals/socket";
import usuario from "../../signals/usuario";
import { atendimentoEncerrado, emAtendimento, idSalaAtendimento, mensagemResposta } from "../../signals/atendimentos";
import EnvioAnexoContainer from "../anexos/modal-envio";
import { EVENTOS_SOCKET } from "../../common/Constants";
import { SttChatInput } from "@stt-componentes/atendimento";
import { batch } from "@preact/signals-react";

const ChatFormContainer = () => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    useSignals();

    const textoMensagem = useSignal('');
    const inserirAnexo = useSignal(false);
    const usuariosDigitando = useSignal([]);

    useSignalEffect(() => {
        if (socket.value) {
            socket.value.on(EVENTOS_SOCKET.AVISO_DIGITANDO, (dados) => {
                dados = JSON.parse(dados);
                usuariosDigitando.value = dados.filter(d => d.id !== usuario.value.idFuncionario && d.id !== usuario.value.idPaciente);
            })
        }
    });

    useSignalEffect(() => {
        const dadosDigitando = {
            digitando: textoMensagem.value.trim().length > 0,
            idSala: idSalaAtendimento.value,
            nome: usuario.value.nome,
            id: usuario.value.idFuncionario || usuario.value.idPaciente,
        }
        socket.value.emit(EVENTOS_SOCKET.ENVIAR_AVISO_DIGITANDO, JSON.stringify(dadosDigitando));
    });

    const enviarMensagem = () => {
        if (textoMensagem.value === '') {
            return;
        }

        const dadosMsg = {
            mensagem: textoMensagem.value.trim(),
            idSala: idSalaAtendimento.value,
            responsavel: {
                nome: usuario.value.nome,
                id: usuario.value.idFuncionario || usuario.value.idPaciente,
                cpf: usuario.value.cpf,
            }
        }

        if (mensagemResposta.value) {
            dadosMsg.mensagemRespondida = mensagemResposta.value._id;
        }

        socket.value.emit(EVENTOS_SOCKET.ENVIAR_MENSAGEM, JSON.stringify(dadosMsg));
        batch(() => {
            textoMensagem.value = '';
            mensagemResposta.value = null;
        });
    }

    return (
        <>
            <SttChatInput
                strings={strings}
                usuariosDigitando={usuariosDigitando}
                textoMensagem={textoMensagem}
                inserirAnexo={inserirAnexo}
                enviarMensagem={enviarMensagem}
                disabled={atendimentoEncerrado.value || !emAtendimento.value}
                mensagemResposta={mensagemResposta}
            />
            {
                inserirAnexo.value &&
                <EnvioAnexoContainer inserirAnexo={inserirAnexo} />
            }
        </>
    );
};

export default ChatFormContainer;