import React, { useContext } from 'react';
import axios from 'axios';
import { makeStyles } from "@material-ui/core/styles";
import {
    SttModal,
    SttButton,
    SttGrid,
    SttBasicFileSelect,
    SttLoading,
    SttTranslateHook
} from '@stt-componentes/core';
import { getHeadersFormData } from '../../request';
import { useSignal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';
import { EVENTOS_SOCKET } from '../../common/Constants';
import { idAtendimento, idSalaAtendimento } from '../../signals/atendimentos';
import socket from '../../signals/socket';
import usuario from '../../signals/usuario';
import alerta from '../../signals/alerta';

const useStyles = makeStyles((theme) => ({
    form: {
        marginTop: '10px',
        marginBottom: '30px'
    },
    inputFile: {
        display: 'none'
    }
}));

const EnvioAnexoContainer = ({ inserirAnexo }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();

    useSignals();

    const isUploading = useSignal(false);
    const selectedFile = useSignal(null);
    const progress = useSignal(0);

    const handleClose = () => {
        inserirAnexo.value = false;
    }

    const changeHandler = (event) => {
        if (!event) {
            selectedFile.value = null;
            return;
        }

        if (event.target.files[0]) {
            selectedFile.value = event.target.files[0];
        }
    };

    const anexarArquivo = () => {
        const data = new FormData();
        data.append('anexo', selectedFile.value);
        data.append('idAtendimento', idAtendimento.value);

        const url = `${global.gConfig.url_base_backend}/anexo`;
        isUploading.value = true;
        axios.post(url, data, {
            headers: getHeadersFormData(),
            onUploadProgress: (event) => {
                let p = Math.round(
                    (event.loaded * 100) / event.total
                );
                progress.value = p;
            },
        })
            .then((response) => {
                if (response.data && !response.data.erro) {
                    console.log('enviou anexo');
                    const dadosAnexo = response.data.data;
                    const dadosEnviar = {
                        idSala: idSalaAtendimento.value,
                        anexo: {
                            _id: dadosAnexo._id,
                            nome: dadosAnexo.nome,
                            url: dadosAnexo.urlPreAssinada,
                            imagem: dadosAnexo.imagem
                        },
                        responsavel: {
                            nome: usuario.value.nome,
                            id: usuario.value.idFuncionario || usuario.value.idPaciente,
                            cpf: usuario.value.cpf,
                        }
                    }
                    socket.value.emit(EVENTOS_SOCKET.ENVIAR_ANEXO, JSON.stringify(dadosEnviar));
                    handleClose();
                }
            }).catch((response) => {
                alerta.value = {
                    ...alerta.value,
                    type: 'error',
                    title: strings.erro,
                    message: strings.erroEnviarAnexo,
                    open: true,
                    options: [{
                        title: strings.ok,
                        onClick: () => {
                            alerta.value = {
                                ...alerta.value,
                                open: false
                            }
                        }
                    }],
                    onClose: () => {
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        };
                    }
                }
                console.log(response);
            })
            .finally(() => {
                isUploading.value = false;
                progress.value = 0;
            });
    }

    return (
        <>
            <SttModal
                title={strings.inserirAnexo}
                open={true}
                outModalClose={handleClose}
                iconClose={handleClose}
                maxWidth="md"
                fullWidth={true}
                children={
                    <div className={classes.form}>
                        <SttGrid container spacing={3}>
                            <SttGrid item xs={12} md={12}>
                                <label>
                                    {strings.selecioneArquivo}
                                </label>
                                <SttBasicFileSelect onFileChange={changeHandler} />
                            </SttGrid>
                        </SttGrid>
                    </div>
                }
                footer={
                    <div>
                        <SttButton disabled={!selectedFile.value} variant="contained" color="primary" onClick={anexarArquivo}>
                            {strings.anexar}
                        </SttButton>
                        <SttButton variant="outlined" color="primary" onClick={handleClose}>
                            {strings.fechar}
                        </SttButton>
                    </div>
                }
            />
            <SttLoading
                open={isUploading.value}
                text={strings.enviandoArquivo}
                progress={progress.value}
            />
        </>
    );
};

export default EnvioAnexoContainer;