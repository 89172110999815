import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Home from './paginas';
import {
    SttTranslateHook,
    SttThemeProvider
} from '@stt-componentes/core';
import './styles.css';

class App extends Component {
    render() {
        return (
            <div>
                <SttThemeProvider config={global.gConfig}>
                    <SttTranslateHook.I18nContextProvider modulo={'teleatendimento'}>
                        <Home />
                    </SttTranslateHook.I18nContextProvider>
                </SttThemeProvider>
            </div>
        )
    };

    getChildContext() {
        return {
            modulo: this.props.modulo,
            currentLanguage: this.props.currentLanguage,
        };
    }
}

App.childContextTypes = {
    currentLanguage: PropTypes.string,
    modulo: PropTypes.string.isRequired,
};

export default App;